@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    html, body {
      @apply w-full h-full m-0 p-0;
      overflow-x: hidden;
    }

    body {
      @apply font-sans bg-background text-text dark:bg-background-dark dark:text-text-dark;
    }
}

pre[class*="language-"] {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
}

.ProseMirror-focused {
  outline: none;
}

.ProseMirror ul, .ProseMirror ol {
  padding-left: 30px;
}

.ProseMirror li {
  position: relative;
}

.ProseMirror li > p {
  margin: 0;
}

.ProseMirror ul {
  list-style-type: disc;
}

.ProseMirror ol {
  list-style-type: decimal;
}

::marker {
  color: inherit !important;
}

.overflow-y-auto {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.overflow-y-auto::-webkit-scrollbar {
  display: none;  /* Chrome, Safari and Opera */
}

.placeholder {
    color: #aaa;
    pointer-events: none;
    position: absolute;
    padding: 0;
}

.katex-container {
  contain: content;
  overflow-x: auto;
  max-width: 100%;
}

.katex-display {
  overflow-x: auto;
  overflow-y: hidden;
}

.katex-html {
  white-space: normal;
}